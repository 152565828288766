<template>
	<v-container fluid>
		<div class="text-right">
			<v-btn rounded color="primary" @click="createBrand()">
				+ Create Brand
			</v-btn>
		</div>

		<v-row class="mt-4">
			<v-col
				cols="12"
				md="6"
				lg="4"
				class="d-flex flex-column"
				v-for="brand in brands"
				:key="brand.id"
			>
				<v-card class="px-2 py-4" min-height="150" height="100%">
					<v-row class="flex d-flex align-content-start">
						<v-card-title class="py-0">
							{{ brand.name }}
						</v-card-title>

						<v-spacer></v-spacer>

						<v-menu bottom left>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									class="px-4"
									@click="manageBrand(brand)"
								>
									<v-list-item-title>
										Manage
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									class="px-4"
									@click="updateBrand(brand)"
								>
									<v-list-item-title>
										Update
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									class="px-4"
									@click="deleteBrand(brand)"
								>
									<v-list-item-title>
										Delete
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-row>

					<v-row class="flex d-flex align-content-start">
						<v-card-text
							class="
								text-subtitle-2
								grey--text
								text--darken-1
								py-2
							"
						>
							{{ brand.description }}
						</v-card-text>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<div
			v-if="showCreateBrandModal"
			class="justify-center d-flex align-center"
		>
			<CreateBrandModal
				:initial-data="brand"
				@on-brand-update="closeModal"
				@close-modal="closeModal"
			></CreateBrandModal>
		</div>
	</v-container>
</template>

<script>
import CreateBrandModal from '../components/CreateBrandModal'

export default {
	components: {
		CreateBrandModal,
	},
	data() {
		return {
			brand: {},
			showCreateBrandModal: false,
		}
	},
	computed: {
		brands() {
			return this.$store.state.socialMedia.brands
		},
	},
	created() {
		this.getBrandsData()
	},
	methods: {
		getBrandsData() {
			this.$store.dispatch('socialMedia/getBrandsData')
		},
		createBrand() {
			this.brand = {}
			this.showCreateBrandModal = true
		},
		manageBrand(brand) {
			this.$router.push({
				name: 'brand.manage',
				params: { id: brand.id },
			})
		},
		updateBrand(brand) {
			this.brand = brand
			this.showCreateBrandModal = true
		},
		deleteBrand(brand) {
			const projectId = this.$store.state.app.projectId
			this.axios
				.delete(`/${projectId}/social/brands/${brand.id}`)
				.then((res) => {
					console.log(res.data)
					this.$snackbar.notify({
						message: res.data.message,
						color: 'success',
					})
					this.getBrandsData()
				})
				.catch((err) => {
					console.log(err.response)
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		closeModal() {
			this.getBrandsData()
			this.showCreateBrandModal = false
		},
	},
}
</script>
