<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="800px">
			<v-card class="mb-0">
				<div class="d-flex justify-space-between align-start px-6 pt-6">
					<h1 class="font-weight-light crud-title mb-5">Add Brand</h1>
					<span class="mdi mdi-close close" @click="reset()"></span>
				</div>

				<v-col cols="12" class="px-6 py-0">
					<v-row class="d-flex align-center">
						<v-col cols="12" class="pa-0">
							<v-text-field
								v-model="brandForm.name"
								solo
								flat
								label="Name"
								placeholder="E.g. Willy Wonka Ltd."
								outlined
								:error-messages="nameErrors"
								@blur="$v.brandForm.name.$touch()"
							>
							</v-text-field>

							<v-textarea
								v-model="brandForm.description"
								solo
								flat
								label="Description"
								placeholder="E.g. Willy Wonka Ltd. is a manufacturer and marketer of food products and beverages. The company's product portfolio comprises chocolates and confectionery, coffee, culinary products, chilled and frozen foods, dairy products, nutritional products, ice cream."
								no-resize
								outlined
								counter="255"
								:error-messages="descriptionErrors"
								@blur="$v.brandForm.description.$touch()"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>

				<v-divider></v-divider>

				<v-card-actions class="pa-6 card-actions">
					<UnoSaveButton
						@click.native="createBrand()"
						@primary-button-click="brandListing()"
						primary-button-text="Move To Brand Listing"
						secondary-button-text="Continue Editing"
						:saving="isSaving"
					></UnoSaveButton>

					<v-spacer></v-spacer>

					<UnoDeleteButton
						v-if="isEdit"
						@confirm="deleteBrand()"
						:deleting="isDeleting"
					></UnoDeleteButton>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'

export default {
	mixins: [validationMixin],
	validations: {
		brandForm: {
			name: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(100),
			},
			description: {
				required,
				minLength: minLength(10),
				maxLength: maxLength(255),
			},
		},
	},
	props: {
		initialData: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
	components: {
		UnoSaveButton,
		UnoDeleteButton,
	},
	data() {
		return {
			id: '',
			brandForm: {
				name: '',
				description: '',
			},
			loading: false,
			dialog: true,
			isEdit: false,
			isSaving: false,
			isDeleting: false,
		}
	},
	computed: {
		nameErrors() {
			const errors = []
			if (!this.$v.brandForm.name.$dirty) return errors
			!this.$v.brandForm.name.required &&
				errors.push('Please enter brand name.')
			!this.$v.brandForm.name.minLength &&
				errors.push('Name must be atleast 3 characters long.')
			!this.$v.brandForm.name.maxLength &&
				errors.push('Name must be atmost 100 characters long.')
			return errors
		},
		descriptionErrors() {
			const errors = []
			if (!this.$v.brandForm.description.$dirty) return errors
			!this.$v.brandForm.description.required &&
				errors.push('Please enter brand description.')
			!this.$v.brandForm.description.minLength &&
				errors.push('Description must be atleast 10 characters long.')
			!this.$v.brandForm.description.maxLength &&
				errors.push('Description must be atmost 255 characters long.')
			return errors
		},
	},
	created() {
		if (Object.keys(this.initialData).length) {
			this.id = this.initialData.id
			this.brandForm.name = this.initialData.name
			this.brandForm.description = this.initialData.description
			this.isEdit = true
		}
	},
	methods: {
		createBrand() {
			this.$v.brandForm.$touch()
			if (!this.$v.brandForm.$invalid || !this.$v.brandForm.$error) {
				this.isSaving = true
				const projectId = this.$store.state.app.projectId
				this.axios({
					method: this.isEdit ? 'put' : 'post',
					url: this.isEdit
						? `/${projectId}/social/brands/${this.id}`
						: `/${projectId}/social/brands`,
					data: this.brandForm,
				})
					.then((res) => {
						console.log(res.data)
						this.isSaving = false
						this.$emit('close-modal')
						this.$snackbar.notify({
							message: res.data.message,
							color: 'success',
						})
					})
					.catch((err) => {
						this.isSaving = false
						this.$snackbar.notify({
							message: err.response.data.message,
							color: 'error',
						})
					})
			}
		},
		reset() {
			if (!this.isEdit) {
				this.brandForm = {}
			}
			this.$emit('close-modal')
		},
		deleteBrand() {
			this.isDeleting = true
			const projectId = this.$store.state.app.projectId
			this.axios
				.delete(`/${projectId}/social/brands/${this.brand.id}`)
				.then((res) => {
					this.isDeleting = false
					this.$emit('close-modal')
					this.getBrandsData()
					this.$snackbar.notify({
						message: res.data.message,
						color: 'success',
					})
				})
				.catch((err) => {
					this.isDeleting = false
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
	},
}
</script>

<style scoped>
.headline {
	font-weight: 400 !important;
}
.save-btn {
	color: white !important;
}
.trash-btn:hover {
	background-color: #ffebee;
}
.restore-btn:hover {
	background-color: #e8f5e9;
}
.card-actions .icon {
	font-size: 17px;
}
</style>
